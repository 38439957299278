import React from 'react';
import './actvities.css';
import Footer1 from "./egfooter.js"

const activities = [
  {
    title: 'Art & Craft',
    images: [
      './images/activities/act7.jpeg',
     './images/activities/craft2.jpeg',
     './images/activities/craft3.jpeg',
    ],
    description: `Art and craft activities are essential in a child's educational journey. They foster creativity, fine motor skills, and self-expression, helping children explore their imagination. Through engaging with various materials like paper, crepe paper, plastic, oil pastels, colored pencils, glitters, threads, and cotton, children can create diverse artistic forms. These activities also promote problem-solving abilities and cultural awareness as children learn about different traditions and artistic techniques. Moreover, art and craft encourage patience, concentration, and emotional expression, providing a platform for personal growth. The tactile experience of working with different textures and tools enhances sensory development and hand-eye coordination. As a part of a well-rounded education, art and craft nurture both artistic talents and essential life skills, making them a fun yet meaningful way to spend time.`,
  },
  {
    title: 'Drawing',
    images: [
      './images/activities/act12.jpeg',
     './images/activities/draw2.jpeg',
     './images/activities/act10.jpeg',
    ],
    description: `Drawing is a valuable educational tool that nurtures creativity, fine motor skills, and visual thinking in students. Through drawing, children can express their ideas and emotions, making it a powerful medium for self-expression. It also helps them understand concepts in subjects like science and history by allowing them to visualize and sketch out ideas. When drawing forms, students learn about scale, construction, and spatial relationships, which are crucial for understanding the physical world around them. The act of sketching enhances observation skills, as children learn to perceive and interpret nature and their surroundings. These skills are not only beneficial for artistic pursuits but are also essential for academic tasks like writing, where precision and attention to detail are required. Drawing, therefore, plays a critical role in a child’s cognitive and emotional development, making it an integral part of their education.`,
  },
  {
    title: 'Clay Modeling',
    images: [
      './images/activities/act1.jpg',
     './images/activities/clay2.JPG',
     './images/activities/clay3.JPG',
    ],
    description: `Clay modeling offers a tactile and sensory-rich experience for children, allowing them to explore their creativity while enhancing fine motor skills. The malleable nature of clay encourages children to mold and shape it into various forms, such as animals, objects, or even fun creations like chapatis, chocolates, bread, and cakes. This hands-on activity provides a unique blend of fun and learning, as children engage in the process of creation. Working with clay also fosters spatial awareness and hand-eye coordination, as children manipulate the material to achieve their desired outcomes. Additionally, clay modeling helps children develop patience and focus, as the process requires time and attention to detail. The sensory experience of touching and shaping the clay adds to the overall enjoyment and learning, making it a popular choice among young learners. Through clay modeling, children not only express their artistic talents but also develop essential life skills that contribute to their overall growth.

`,
  },
  {
    title: 'Newspaper Reading',
    images: [
      './images/activities/news.jpeg',
     './images/activities/news.jpeg',
     './images/classroom/class7.jpg',
    ],
    description: `Newspaper reading plays a crucial role in a student’s life, serving as a window to the world and a mirror of society. Newspapers are an important and effective medium of mass communication, providing students with up-to-date information on global events, science, technology, and culture. Engaging in regular newspaper reading enhances students' general knowledge and keeps them informed about current affairs. It also develops critical thinking skills as they analyze news reports, form opinions, and understand different perspectives. For children, reading newspapers can be a pleasurable experience, fostering a habit of lifelong learning.As each child learns to read at their own pace, it is essential to introduce novel approaches to cultivate good reading habits.`,
  }
];

const ActivitiesPage = () => {
  return (
    <>
    <div className="activities-page">
      {activities.map((activity, index) => (
        <div className="activity" key={index}>
          <h2>{activity.title}</h2>
          <div className="images">
            {activity.images.map((image, idx) => (
              <img src={image} alt={`${activity.title} ${idx + 1}`} key={idx} />
            ))}
          </div>
          <p>{activity.description}</p>
        </div>
      ))}
    </div>
    <div><Footer1/></div>
    </>
  );
};

export default ActivitiesPage;
