// src/Footer.js
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import './footer.css';
import logo from "./assests/image/logo.jpg"
import { Link } from 'react-router-dom';
const Footer1 = () => {
  return (
    <footer>
      <div className="container">
        <div className="section">
          <div className="logo-container">
            <img src={logo} alt="mlbt School Logo" className="logo" />
            <h3>GVP MLBT SCHOOL</h3>
          </div>
          <div className="address">
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" /> East Point Colony, Visakhapatnam - 530017
            </p>
            <p>
              <FontAwesomeIcon icon={faPhoneAlt} className="icon" /> Office Landline: 0891-2512355
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} className="icon" /> gvpmlbtschool@gmail.com
            </p>
          </div>
        </div>
        <div className="section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/syllabus">Syllabus</a></li>
            <li><a href="/board">Administration</a></li>
            <li><a href="/gallery">Gallery</a></li>
            <li><a href="/contact">Contact Us</a></li>
          </ul>
        </div>
        <div className="section">
          <h3>Location</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3800.421477381837!2d83.33530737369833!3d17.724769093001182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3944a72f17fee9%3A0x853b0bb456d1d30f!2sGayatri%20Vidya%20Parishad%20Mlbt%20School!5e0!3m2!1sen!2sin!4v1719293274025!5m2!1sen!2sin"
            allowFullScreen=""
            aria-hidden="true"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
      <div className="bottom-bar">
        <p>&copy; Copyright GVP-MLBT SCHOOL 2024 |Developed by <a href='https://www.linkedin.com/in/chandu-varma-b97535249/' target='_blank' className='linkedin'>P.Prasad Varma</a></p>
      </div>
    </footer>
  );
};

export default Footer1;