import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './eg';
import Home from './Home';
import Syllabus from './syllabus';
//import About from './About';
//import Academics from './Academics';
//import Administration from './Administration';
import Photo from './cards';
import AboutSchool from './About';
import ContactUs from './contact';
import Results from './results';
import Board from './board';
import PrincipalMessage from './Principalmssg';
import ActivitiesPage from './act';
import FounderSection from './founders';
import Achievements from './achivement';
//import './App.css';
import ScrollingText from './scrolling';
import DressCode from './dresscode';
import ScrollToTop from './refresh';
const Navg = () => {
    return (
        <Router>
            <div className='App'>
                <ScrollToTop/>
            <ScrollingText /> {/* Render ScrollingText component */}
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/gallery" element={<Photo />} />
                    <Route path="/about" element={<AboutSchool />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/results" element={<Results />} />
                    <Route path="/syllabus" element={<Syllabus/>} />
                    <Route path="/board" element={<Board/>} />
                    <Route path="dresscode" element={<DressCode/>} />
                    <Route path="/Principalmssg" element={<PrincipalMessage/>} />
                    <Route path="/act" element={<ActivitiesPage/>} />
                    <Route path="/founders" element={<FounderSection/>} />
                    <Route path="/achivement" element={<Achievements/>} />

                </Routes>
            </div>
        </Router>
    );
};

export default Navg;
