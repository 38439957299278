// src/App.js
import React from 'react';
import './board.css';
import President from './assests/image/Prof. Dr. Ing. P.S. Rao.jpg';
import Vice from './assests/image/D.Dakshinamurthy.jpg';
import Secretary from './assests/image/P. Somaraju.jpg';
import JointSecretary from './assests/image/DVS KamewaraRao.jpg';
import member from './assests/image/P. Rajaganapathi.jpg';
import trustee1 from './assests/image/eg.jpg';
import trustee2 from './assests/image/eg.jpg';
import Footer1 from './egfooter';
const BoardMember = ({ photo, message, name, designation, experience }) => {
  return (
    <div className="board-member">
      <div className="board-member-designation-container">
        <p className="board-member-designation">{designation}</p>
      </div>
      <div className="board-member-photo-container">
        <img src={photo} alt={name} className="board-member-photo" />
        <div className="board-member-details">
          <p className="board-member-name">{name}</p>
          <p className="board-member-experience">{experience}</p>
        </div>
      </div>
      <div className="board-member-info">
        <p className="board-member-message-intro">Message:</p>
        <p className="board-member-message">{message}</p>
      </div>
    </div>
  );
};

const boardMembers = [
  {
    photo: President,
    name:'Prof.P.Srinivas Rao',
    message:"The Gayatri Vidya parishad Mallimadugula Lalithamba Bangarayya Trust School is an exceptional school. It stands out among all other schools not just because it offers a holistic milieu of education but also because of its unwavering commitment to continual improvement and innovative initiatives. It is also exceptional because of the emphasis we place on setting higher challenges for children. We consider that 'Excellence is never an accident' it's always the result of high intention, sincere effort, intelligent direction and skillful execution.GVP-MLBT is not just a school; it is a movement. A movement which began 37 years ago, with a vision of providing a schooling experience that goes beyond the limitations of a classroom and makes learning an exciting journey of discovery and self exploration. The children here are made to realize the best they are and trained to think big. They are groomed to be the leaders and not the led.We stand at the others hold of a new challenge and opportunity. The challenge comprises in blending technology in harmony with traditional ethyls in a society where knowledge is becoming a greatest strength. The opportunity is of transforming the potential of every single child to become a productive contributor to the growth and development of our nation.Join us in this sojourn of education for life. Together, we can create a better tomorrow for our children.',    name: 'Prof. P. Srinivasa Rao",
    designation: 'President-Gayatri Vidya Parishad',
    experience: 'B.Tech. (Hons), M.Tech., Dr.Ing (Munich)'
  },
  {
    photo: Vice,
    name: 'D. Dakshina Murthy',
    message: 'It is indeed a great pleasure to submit these few lines for our first school magazine. The seed that was planted in 1985 has blossomed into a great educational centre. That the GVP MLBT School was established by Mallimadugula Jaganadham Rao and Vijaya Lakshmi in the Year 1985. Gayatri Vidya Parishad took over the responsibility of the school in the year 2003 and we named as GVP MLBT School with 75 students and at present 900 students are studied in the school. It is our endeavor and an efforts towards creating a wider awareness of the society and giving space for multidimensional development of that students as well as teachers. I hope students passing out from GVP MLBT School would grow into self aware, sensitive and responsible human being who remain life long learners. I extend my warm wishes to the principal, staff and students GVP MLBT to continue this journey on the road of excellence.',
    designation: 'Vice President-Gayatri Vidya Parishad',
    experience: 'M.Sc'
  },
  {
    photo: Secretary,
    message:" I would like to thank the almighty for the past and placing ourselves under his protection and guidance for the future. We have completed 32 years of striving, dedication, ded hard work and sincerity in our endevaour for providing quality education looking back on the past years one has to acknowledge that GVP MLBT School has done an excellent and outstanding service for the cause of education in the most longenial and healthy environment. For needy children. The concept of education has changed in a big and seemingly unmanageable way in the recent time. Man's adherence to and rejection to certain practices have thrown up new challenges. Today education has opned new vistas. Improvement of self remains not only a non - changing factor but has assumed greater significance. School and teachers have a more improvement role to play in the life of a student. He / she has to accomplish something more than imparting knowledge. There has never been a greater need to respect to a child's sensitivities. Teachers, in order to ensure for open, orderly and optimum growth of child have to mark an effort to learn to respect his / her personality. The schools and teachers must ensure that the child does not have to work under stress, it should, in any case have to reduce to sheer minimum. Now technology is a part of the education and GVP Creating human excellence for a better society.",    name: 'Prof. P. Soma Raju',
    designation: 'Secretary-Gayatri Vidya Parishad',
    experience: 'M.A, Ph.D.'
  }, 
  {
    photo: JointSecretary,
    message:'GAYATRI VIDYA PARISHAD MLBT School value oriented education is the need of the hour. As the HAD ide a vide AY world is filled with unethical living, urest at all levels, violence and wars, the value of human life has touched the lowest depths degradatin. of The devaluation of man has cost him his own safety and security and the very existence of the planet earth is threatened. In order to impart awareness of education and value based training in the early age, GVP MLBT School has initiated value based education through various programme like, summer camp, special training programme as supplementry measures and through regular pedagogy in our educational insititutions.',    name: 'Prof. D V S Kameswara Rao',
    designation: 'Joint Secretary-Gayatri Vidya Parishad',
    experience: 'Bachelor of Engineering'
  }, {
    photo: member,
    message: "GAYATRI VIDYA PARISHAD Educational Institutions provide a holistic approach to provide 'Education for life' in addition to 'Education for living'. In addition to providing the best possible education in terms of academic excellence, facilities, ambience, pedagogy and infrastructure, the education one receives at Gayatri instills and inculcates both values and virtues, while sensitizing them to the essential principles and values.An education which caters to both the external and internal, to both the head and the heart, one which creates an atmosphere to help students understand that serving the society with a selfless attitude is also of paramount importance. All the students of our School have ample opportunities for orientation in value based and cultural education. The students have also demonstrated their social commitment and community engagement by participating in various activities - clean India awareness drive and campaigns in their communities as well as wholeheartedly participating in Swaach Bharath etc also.",
    name: 'Prof. P. Raja Ganapathi',
    designation: 'Member-Gayatri Vidya Parishad',
    experience: 'M.Sc, Ph.D.'
  },  {
    photo: trustee1,
    message:'It is both young and immensely dynamic, with a unique holistic approach to produce leaders in all of its disciplines. At Gayatri Vidya Parishad, we have assembledsome of the most well known academicians, enterpreneurs, executives, inventors, philan- thropists, researches, and scientists to guide us in this noble endeavors.A very hearty welcome to you to this sacred temple of learning and leadership. on 19th June 1986 MLBT School started by Vedula Varaha Narasimha Rao.',
    name: 'Dr.V R Mayadevi',
    designation: 'Managing Trustee-MLBT Trust',
    experience: 'Freedom Fighter'
  },
  {
    photo: trustee2,
    message:'lam very happy to bring out a news letter named young achievers to promote the inherent talent among the children and about the programmes organised by GVP MLBT School. It is to inspire the students about their social and moral responsibility towards society and the country. The teachers are like drill masters, demonstrating the values in their lives before asking the students to follow and are like students to follow and are like teachers who practice whatever they profess. Like the sculptors who shape the rocks into the things of beauty for adoration, they mould the students.',    name: 'Smt.V Antervedi',
    designation: 'President-MLBT Trust',
    experience: ''
  }
];

const Board = () => {
  return (
    <>
    <div className="App">
      <div className="title">Board of Governance</div>
      {boardMembers.map((member, index) => (
        <BoardMember
          key={index}
          photo={member.photo}
          message={member.message}
          name={member.name}
          designation={member.designation}
          experience={member.experience}
        />
      ))}
    </div>
    <div><Footer1/></div>
    </>
  );
};

export default Board;
