/*import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import './eg.css';
import logo from "./assests/image/logo.jpg";
import ScrollingText from './scrolling';

const Navbar = () => {
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");
      if (window.pageYOffset > 0) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className='scrolling-container'>
        <ScrollingText />
      </div>
      <div className='nav'>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <Link className="navbar-brand d-flex align-items-center" to="/">
              <img src={logo} alt="Logo" className="d-inline-block align-text-top logo" />
              <div className="header">
                <span className="school-name">Gayatri Vidya Parishad</span>
                <span className="sub-name">MLBT School</span>
              </div>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link to="/" className="nav-link">Home</Link>
                </li>
                <li className="nav-item dropdown">
                  <Link to="/about" className="nav-link dropdown-toggle" id="navbarDropdownAbout" role="button" aria-expanded="false">About Us</Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownAbout">
                    <li><Link className="dropdown-item" to="/about">About School</Link></li>
                    <li><Link className="dropdown-item" to="/about/">Principal's Message</Link></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link to="/academics" className="nav-link dropdown-toggle" id="navbarDropdownAcademics" role="button" aria-expanded="false">Academics</Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownAcademics">
                    <li><Link className="dropdown-item" to="/syllabus">Syllabus</Link></li>
                    <li><Link className="dropdown-item" to="/results">Results</Link></li>
                    <li><Link className="dropdown-item" to="/academics/curriculum">Achivements</Link></li>
                    <li><Link className="dropdown-item" to="/academics/curriculum">Workshops & Seminars</Link></li>

                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link to="/administration" className="nav-link dropdown-toggle" id="navbarDropdownAdministration" role="button" aria-expanded="false">Administration</Link>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownAdministration">
                  <li><Link className="dropdown-item" to="/admin">Founder's</Link></li>
                    <li><Link className="dropdown-item" to="/board">Board of Governors</Link></li>
                    <li><Link className="dropdown-item" to="/dresscode">Dress Code</Link></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link to="/gallery" className="nav-link">Gallery</Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;*/
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import './eg.css';
import logo from "./assests/image/logo.jpg";
import ScrollingText from './scrolling';

const Navbar = () => {
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");
      if (window.pageYOffset > 0) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleLinkClick = () => {
      const navbarToggler = document.querySelector(".navbar-toggler");
      const navbarCollapse = document.querySelector(".navbar-collapse");
      if (navbarToggler && navbarCollapse.classList.contains("show")) {
        navbarToggler.classList.add("collapsed");
        navbarCollapse.classList.remove("show");
      }
    };

    const navLinks = document.querySelectorAll('.nav-link:not(.dropdown-toggle)');
    navLinks.forEach(link => link.addEventListener('click', handleLinkClick));

    return () => {
      navLinks.forEach(link => link.removeEventListener('click', handleLinkClick));
    };
  }, []);

  return (
    <>
      <div className='scrolling-container'>
        <ScrollingText />
      </div>
      <div className='nav'>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <Link className="navbar-brand d-flex align-items-center" to="/">
              <img src={logo} alt="Logo" className="d-inline-block align-text-top logo" />
              <div className="header">
                <span className="school-name">Gayatri Vidya Parishad</span>
                <span className="sub-name">MLBT School</span>
              </div>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link to="/" className="nav-link">Home</Link>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" id="navbarDropdownAbout" role="button" data-bs-toggle="dropdown" aria-expanded="false">About Us</a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownAbout">
                    <li><Link className="dropdown-item" to="/about">About School</Link></li>
                    <li><Link className="dropdown-item" to="/Principalmssg">Principal's Message</Link></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" id="navbarDropdownAcademics" role="button" data-bs-toggle="dropdown" aria-expanded="false">Academics</a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownAcademics">
                    <li><Link className="dropdown-item" to="/syllabus">Syllabus</Link></li>
                    <li><Link className="dropdown-item" to="/results">Results</Link></li>
                    <li><Link className="dropdown-item" to="/achivement">Achivements</Link></li>
                    <li><Link className="dropdown-item" to="/act">Activities</Link></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" id="navbarDropdownAdministration" role="button" data-bs-toggle="dropdown" aria-expanded="false">Administration</a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownAdministration">
                    <li><Link className="dropdown-item" to="/founders">Founder's</Link></li>
                    <li><Link className="dropdown-item" to="/board">Board of Governors</Link></li>
                    <li><Link className="dropdown-item" to="/dresscode">Dress Code</Link></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link to="/gallery" className="nav-link">Gallery</Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
