import React from 'react';
import './contact.css';
import '@fortawesome/fontawesome-free/css/all.css'; // Correct FontAwesome CSS import
import Footer1 from './egfooter';
function ContactUs() {
  return (
    <div>
      <div className="heading">School Hours</div> {/* Adjusted heading */}
      <div className="container">
        <div className="card">
          <h2>School Hours</h2>
          <div className="content">
            <p className='cl'>Class VI to X</p>
            <p>9.00 A.M. to 4.30 P.M.</p>
            <p className='cl'>Class LKG to V</p>
            <p>9:00 A.M. to 3.30 P.M.</p>
          </div>
        </div>
        <div className="card">
          <h2>Office Hours</h2>
          <div className="content">
            <p>9.00 A.M. TO 3.30 P.M.</p>
            <p>9 A.M. TO 12.00 NOON</p>
            <p>During Saturdays and Vacations</p>
          </div>
        </div>
        <div className="card">
          <h2>Visiting Hours</h2>
          <div className="content">
            <p className='cl'>To meet the Principal</p>
            <p>9.00 A.M. to 03.00 P.M.</p>
            <p className='cl'>To meet the Class Teachers</p>
            <p>9:00 A.M. to 12:00 Noon(Only on Saturday's & P.T.M day)</p>
          </div>
        </div>
      </div>
      <div className="contact-info">
        <h1>Address</h1>
        <h2>Gayatri Vidya Parishad Mlbt School</h2>
        <div className="contact-details">
          <p><i className="fas fa-map-marker-alt"></i> D.No 6-23-5/1,East Piont Colony, Visakhapatnam - 530017</p>
          <p><i className="fas fa-phone"></i> 0891-2512355</p>
          <p><i className="fas fa-phone"></i> 7382203399</p>
          <p><i className="fas fa-envelope"></i> gvpmlbtschool@gmail.com</p>
        </div>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3800.421477381837!2d83.33530737369833!3d17.724769093001182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3944a72f17fee9%3A0x853b0bb456d1d30f!2sGayatri%20Vidya%20Parishad%20Mlbt%20School!5e0!3m2!1sen!2sin!4v1719293274025!5m2!1sen!2sin"
            width="600"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            title="Visakha Valley School Location"
          ></iframe>
        </div>
      </div>
      <Footer1/>
    </div>
  );
}

export default ContactUs;
