import React from 'react';
import './ScrollingText.css';

const ScrollingText = () => {
    const news = ["ADMISSIONS ARE OPENED FOR THE ACADEMIC YEAR 2024-25",
"CURRENTLY OUR CHILDREN ARE DOING INFOSYS CERTIFICATIONS"
    ];
    return (
        <div className="scrolling-container">
            <div className="announcements-label">
                Announcements
            </div>
            <div className="scrolling-text">
                <div className="scrolling-content">
                {news.map((item, index) => (
                        <span key={index} className="scrolling-item">
                            <span className="bullet-point">&#9679;</span> {item} &nbsp;&nbsp;
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ScrollingText;
