import React from 'react';
import './founders.css';
import founder from "./assests/image/founders.png"; // Fixed typo in the path
import Footer1 from './egfooter';
const FounderSection = () => {
  return (
    <>
    <div className="founder-section-container">
      <div className="founder-header">
        <h1>Founder's of MLBT</h1>
      </div>
      <div className="founder-section">
        <img 
          src={founder} 
          alt="Founder" 
          className="founder-photo" 
        />
        <div className="founder-info">
          <h2>M. Jagannadha Rao & Dr. M. Vijaya Lakshmi</h2>
          <p>
          Mallimadugula Lalithamba Bangaraiah Trust was founded by eminent freedom fighter and social worker late Mallimadugula Jagannatha Rao in Memory of his parents late Mallimadugula Lalithamba & Bangaraih, veterans freedom fighters, Gandhian Principles and philanthropists. Its main objective is to uphold Gandhian principles, by uplifting the downtrodden women and children of the slum areas in and around Visakhapamam by promoting and encouraging education, culture, scientific attitude. philosophical outlook, upholding human and moral values and giving training in handicrafts. Promoting cottage industries and other useful crafts and encouraging production units and creating part of full time employment to the women and children of the slum in and around Visakhapatnam.
          </p>
        </div>
        <div className="objectives-section">
          <h3>Objectives of the Trust</h3>
          <ol>
            <li>To promote and uplift Gandhian principles.</li>
            <li>To conduct and promote such charitable activities for the general welfare of the people in the State of Andhra Pradesh.</li>
            <li>To promote and encourage education, cultural activities, scientific attitude, philosophical outlook, to uphold moral and human values, to give training in handicrafts, cottage industries and to encourage or start production units to help create part time or full time employment.</li>
            <li>To establish and maintain charitable dispensaries, maternity and child welfare centers, homes for destitute and orphans in rural areas and institutions of similar nature, and promote preventive and curative measures affecting the health of the villagers;</li>
            <li>To render help by grants and contributions to any existing institutions which have been carrying out or undertaking work falling within all or any of the objectives mentioned above.</li>
          </ol>
        </div>
      </div>
    </div>
    <div><Footer1/></div>
    </>
  );
};

export default FounderSection;
