import React, { useState } from 'react';
import './cards.css';
import Footer1 from './egfooter';

const imageData = [
  {
    id: 1,
    title: 'CLASSROOMS',
    description: `In GVP MLBT School we have 20 class rooms with species environment. Class rooms are important for the students to feel safe and comfortable in their learning environment. Our class rooms more supportive and can boost confidence and achievement. Our Class rooms well ventilated. There is an open almarah’s where kids keep all the essentials (like Water Bottles, Bags, teaching aids etc. 

Our class rooms are equipped with the latest technology and resources to enhance the learning experience. Our teachers delivers lessons in innovative and interactive ways. Our dedicated teachers are the cornerstone of our classrooms. There are passionate, highly qualified and continuously strive to create engaging and meaning full experiences.  We ensure that each student received personalised attention and support.

Our Teaching Techniques:
Digital Lab with projector, and TV Room,

For pre-primary Class rooms are design for a verity of activities such as  Games, puzzles, toys,  storytelling, practical orientation, enhancing their vocabulary, listening skills. All these activities make this children hands on experience. We will focus fundamental skills and social development for the age 4 to 6 years. 
`,
    image: './images/classroom/class1.jpg',
    additionalImages: [
      { src: './images/classroom/class1.jpg', alt: 'Classroom 1' },
      { src: './images/classroom/class2.jpeg', alt: 'Classroom 2' },
      { src: './images/classroom/class6.jpg', alt: 'Classroom 3' },
      { src: './images/classroom/class5.jpg', alt: 'Classroom 4' },
      { src: './images/classroom/class4.jpg', alt: 'Classroom 5' },
      { src: './images/classroom/class3.jpg', alt: 'Classroom 6' },
      { src: './images/classroom/class7.jpg', alt: 'Classroom 7' },
      { src: './images/classroom/skl11.jpeg', alt: 'Classroom 7' },

    ],
  },
  {
    id: 2,
    title: 'SCHOOL',
    description: ``,
    image: './images/schools/skl7.JPG',
    additionalImages: [
      { src: './images/schools/skl1.jpg', alt: 'School 1' },
      { src: './images/schools/skl3.JPG', alt: 'School 1' },
      { src: './images/schools/skl.JPG', alt: 'School 2' },
      { src: './images/schools/skl12.JPG', alt: 'School 2' },
      { src: './images/schools/skl13.jpeg', alt: 'School 2' },
      { src: './images/schools/WhatsApp Image 2024-03-23 at 11.55.04.jpeg', alt: 'School 3' },
      { src: './images/schools/WhatsApp Image 2023-11-14 at 14.46.45 (1).jpeg', alt: 'School 4' },
      { src: './images/schools/Copy of WhatsApp Image 2024-03-22 at 12.20.03 (2).jpeg', alt: 'School 5' },
      // ... more school images
    ],
  },
  {
    id: 3,
    title: 'COMPUTER LABS',
    description: `Computer labs in schools serve as dedicated spaces where students can use computers for various educational purposes. They are often equipped with desktop computers, printers, and internet access. These labs are used for a range of activities, including:

Students can access information online, work on presentations, and complete assignments.
Software learning provide hands-on experience with different software applications, including word processors, spreadsheets, and educational programs.

Digital Literacy:  help students develop essential digital skills and familiarize themselves with technology.

The lab environment supports both individual and group work, and often teachers use it to integrate technology into their lessons.`,
    image: './images/labs/lab1.jpeg',
    additionalImages: [
      { src: './images/labs/lab1.jpeg', alt: 'Lab 1' },
      { src: './images/labs/lab2.jpeg', alt: 'Lab 2' },
      { src: './images/labs/lab3.jpeg', alt: 'Lab 3' },
      { src: './images/labs/lab4.jpeg', alt: 'Lab 4' },
      { src: './images/labs/lab5.jpeg', alt: 'Lab 5' },
      { src: './images/labs/lab6.jpeg', alt: 'Lab 6' },
      { src: './images/labs/lab7.jpeg', alt: 'Lab 7' },
      { src: './images/classroom/class7.jpg', alt: 'Classroom 7' },
    ],
  },
  {
    id: 4,
    title: 'ACTIVITIES',
    description: ``,
    image: './images/activities/act4.jpeg',
    additionalImages: [
      { src: './images/activities/act1.JPG', alt: 'Activity 1' },
      { src: './images/activities/act2.jpeg', alt: 'Activity 2' },
      { src: './images/activities/act3.jpeg', alt: 'Activity 2' },
      { src: './images/activities/act4.jpeg', alt: 'Activity 2' },
      { src: './images/activities/act5.jpeg', alt: 'Activity 2' },
      { src: './images/activities/act6.jpeg', alt: 'Activity 2' },
      { src: './images/activities/act7.jpeg', alt: 'Activity 2' },
      { src: './images/activities/act8.jpeg', alt: 'Activity 2' },
      { src: './images/activities/act9.jpeg', alt: 'Activity 2' },
      { src: './images/activities/act10.jpeg', alt: 'Activity 2' },
      { src: './images/activities/act11.jpeg', alt: 'Activity 2' },
      { src: './images/activities/act12.jpeg', alt: 'Activity 2' },
      { src: './images/activities/act13.jpeg', alt: 'Activity 2' },
      { src: './images/activities/act14.jpeg', alt: 'Activity 2' },
      { src: './images/activities/act15.jpeg', alt: 'Activity 2' },
      { src: './images/activities/draw2.jpeg', alt: 'Activity 2' },

    ],
  },
  {
    id: 5,
    title: 'GAMES',
    description: `Sports activities establish the qualities of discipline which help in every field of life. The sports activities also teach the physical & mental training to students. Regular physical exercise & participation can help students maintain overall well-being & keep them active. 
Games & Sports: 
1. Running 
2. Jumping
3. Skipping
4. Caroms.
5. Ring
6. Badminton 
7. Kho-Kho
8. Kabaddi
9. Throw ball
10. Volleyball 
11. Football 
12. Dodge Ball
13. Dog in the Bone
14. Relay Race
15. Frog Jumps.  
`,
    image: './images/sports/sports2.jpeg',
    additionalImages: [
      { src: './images/sports/sports1.jpeg', alt: 'Game 1' },
      { src: './images/sports/sports2.jpeg', alt: 'Game 1' },
      { src: './images/sports/sports3.jpeg', alt: 'Game 1' },
      { src: './images/sports/sports4.jpeg', alt: 'Game 1' },
      { src: './images/sports/sports5.jpeg', alt: 'Game 1' },
      { src: './images/sports/sports6.jpeg', alt: 'Game 1' },
      { src: './images/sports/sports7.jpeg', alt: 'Game 1' },
      { src: './images/sports/sports8.jpeg', alt: 'Game 1' },
      { src: './images/sports/sports9.jpeg', alt: 'Game 1' },
      { src: './images/sports/sports10.jpeg', alt: 'Game 1' },
      { src: './images/sports/sports11.jpeg', alt: 'Game 1' },
      { src: './images/sports/sports12.jpeg', alt: 'Game 1' },
    ],
  },
];

const Photo = () => {
  const [selectedActivity, setSelectedActivity] = useState(null);

  const handleCardClick = (activity) => {
    setSelectedActivity(activity);
  };

  const handleBackClick = () => {
    setSelectedActivity(null);
  };

  return (
    <>
<div className="photo-container">
  {selectedActivity ? (
    <div className="activity-details">
      <button className="back-button" onClick={handleBackClick}>Back</button>
      <h2>{selectedActivity.title}</h2>
      <p>{selectedActivity.description}</p>
      <div className="additional-images">
        {selectedActivity.additionalImages.map((img, index) => (
          <img key={index} src={img.src} alt={img.alt} />
        ))}
      </div>
    </div>
      ) : (
        <div className="card-container">
          {imageData.map((activity) => (
            <div
              key={activity.id}
              className="card"
              onClick={() => handleCardClick(activity)}
            >
              <img src={activity.image} alt={activity.title} className="card-image" />
              <div className="card-content">
                <h2>{activity.title}</h2>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
    <div><Footer1/>
    </div>
    </>
  );
};

export default Photo;
