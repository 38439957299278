import React from 'react';
import './principalmssg.css'; // Import the CSS file
import Principal from "./assests/image/principal.jpeg";

const PrincipalMessage = () => {
    return (
        <div className="principal-message">
            <div className="mssg">
                <h1>Principal's Message</h1>
            </div>
            <div className="container">
                <div className="left-container">
                    <img src={Principal} alt="Principal" />
                    <div className="principal-details">
                        <h3>Principal</h3>
                        <p style={{fontSize:'17px' , fontWeight:'bold'}}>K.Madhura Vani<br />
                        M.A.(Eng.,) M.A.(Tel.,) M.Com., M.Ed., M.C.J.,
                        PGDPSE.,M.Phil.,(Ph.D.,)<br /></p>
                    </div>
                </div>
                <div className="right-container">
                    <p style={{fontSize:'19px' , fontWeight:"bolder"}}>Welcome to Gayatri Vidya Parishad MLBT School</p>
                    <p style={{fontSize:'17px' , fontWeight:"bolder"}}>Dear GVP MLBT School,</p>
                    <p style={{fontSize:'17px' , textAlign:"justify"}}>I am K.Madhura Vani, the proud principal of GVP MLBT School. It is my pleasure to welcome you to our school’s website. 
                    Whether you are a current student, parent, staff member, or prospective family, I am thrilled to have you explore what makes our school.
                    At GVP MLBT School, we are dedicated to fostering a nurturing and challenging environment that promotes academic excellence, personal growth, and social responsibility. </p>
                    <p style={{fontSize:'17px' , textAlign:"justify"}}>Our mission is to provide a comprehensive education that prepares our students to be successful, contributing members of society.</p>
                    <p style={{fontSize:'17px' , textAlign:"justify"}}>Our school boasts a range of achievements that reflect our commitment to excellence. From our rigorous academic programs to our vibrant extracurricular activities, 
                    we strive to offer opportunities that cater to the diverse interests and talents of our students.</p>
                </div>
                    <p style={{fontSize:'17px' , textAlign:"justify" , lineHeight:'1.6'}}>Our dedicated teachers and staff work tirelessly to create a supportive and engaging learning environment where every student can thrive.
                        We take great pride in our school community. Our students are encouraged to be inquisitive, compassionate, and respectful individuals. We value the strong partnership we have with our parents and the broader community, 
                        and we believe that collaboration is key to our success.I invite you to explore our website to learn more about our programs, achievements, and the values that guide us. We are always here to answer any questions 
                        you may have and to support you in any way possible.
                        </p ><p style={{fontSize:'16px' , textAlign:"justify" , lineHeight:'1.6' , fontWeight:'bolder'}}>Thank you for visiting our website. I look forward to another fantastic year at GVP MLBT School and to working together to achieve great things.</p>
            </div>
        </div>
    );
};

export default PrincipalMessage;
