import React from 'react';
import './About.css';
import Footer1 from './egfooter';
import School from './assests/image/school.jpeg';

const AboutSchool = () => {
  return (
    <>
      <div className="about-school-wrapper">
        <h2 className="section-title">About School</h2>
        <div className="about-school-container">
          <div className="image-container">
            <img src={School} alt="School" />
          </div>
          <div className="info-container">
            <p>
            <b>History : </b>Gayatri Vidya Parishad MLBT School was established in 1985 by the MLB Trust. The school commenced on June 19, 1986, under the guidance of the eminent teacher Shri V.V.N Rao. The aim of the school is to support underprivileged children and parents from surrounding areas.
</p>
<p>The founders of the school are Shri M. Jaganadha Rao, an eminent advocate and freedom fighter, and Dr. M. Vijaya Lakshmi, who worked with Mahatma Gandhi at Kasturba Ashram. The school was recognized by the Government of Andhra Pradesh in 1993. Shri M. Jaganadha Rao continued to lead the school until his passing on December 4, 1997.
</p>
<p>In 2002, the stewardship of the school was transferred to Gayatri Vidya Parishad by Dr. M. Vijaya Lakshmi, who upheld the founding principles. Dr. M. Vijaya Lakshmi continued to support the school until her passing in 2004.

At present, the school is recognized up to class 10th.


            </p>
            <p></p>
          </div>
          <div>
          <h6 className="section-title">INSPIRATION FOR THE TRUST</h6>
            <p className="full-width-paragraph">
          Late Sri Digumara Venkata Ramiah Pantulu garu (1846-1912) was our great grandfather and was the patriarch of our whole clan and various branches and sub-branches of Digumarti family. He was a great Rama bhakta, and an outstanding person. He earned a name in Berhampur as a lawyer and public prosecutor. Smt. Mallimadugula Lalithamba was his fifth daughter.
He promoted women's education 100 to 150 years ago itself. Even though he got his daughters married in their early age, according to the customs prevailing in those days, he still got all his daughters well educated and knowledgeable.</p>
<p className="full-width-paragraph">He would make them read all the Telugu news papers and he would himself read the English papers and explain the news to them. That is why even though none of us knew him at all, we have heard so much about him and thus he became an ideal person to all of us. Smt. M Lalithamba, inspired by her father, supported by her husband Sri Mallimadugula Bangarayya, along with her friends was instrumental in starting Queen Mary's High School for girls in Visakhapatnam.
When the MLB Trust was formed, Sri M. Jagannadha Rao and his wife Dr. M. Vijaya Lakshmi decided to uphold the ideas and ideals inculcated in them by his grandfather Sri Venkata Ramiah Pantulu and started this Trust, with the objective of providing education to poor children and to women, women empowerment and also uplifting Gandhian principles.
Thus, Sri Digumarti Venkataramiah Pantulu garu has become the major inspiration for Sri Jagannadha Rao and his brothers and sister to start this Trust in the name of their parents.</p>
</div>
        </div>
      </div>
      <Footer1 />
    </>
  );
};

export default AboutSchool;
