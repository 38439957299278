import React from 'react';
import './syllabus.css';
import Footer1 from './egfooter';
const Syllabus = () => {
  return (
    <>
    <div className="syllabus-wrapper">
      <h2 className="school-title">GVP-MLBT SCHOOL : VISKHAPATNAM</h2>
      <h4>SYLLABUS</h4>
      <div className="syllabus-content">
        <h3>CLASSES I - V</h3>
        <p>1st Language: Telugu</p>
        <p>2nd Language: Hindi</p>
        <p>3rd Language: English</p>

        <h3>General Subjects:</h3>
        <p className='extra'>
          Mathematics, General Science, Social Science, Environment Studies, Values Education. Training is provided in Physical & Health Education, Art Education, Information Technology, Yoga, Public Speaking & Talent Enrichment Activities. Uniformity in the Assessment structure, examination and issue of report card is necessary for classes I-V. The detailed components of the scheme are mentioned below for easy understanding.
        </p>
      </div>
      <div className="syllabus-content">
        <h3>CLASSES VI - X</h3>
        <p>1st Language: Telugu</p>
        <p>2nd Language: Hindi</p>
        <p>3rd Language: English</p>

        <h3>General Subjects:</h3>
        <p className='extra'>
          Mathematics, General Science, Social Science, Environment Studies, Values Education. Training is provided in Physical & Health Education, Art Education, Information Technology, Yoga, Public Speaking & Talent Enrichment Activities. Uniformity in the Assessment structure, examination and issue of report card is necessary for classes I-V. The detailed components of the scheme are mentioned below for easy understanding.
        </p>
      </div>
    </div>
    <div><Footer1/></div>
    </>
  );
};

export default Syllabus;
