import React from 'react'; 
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import './Home.css'; // Import the CSS file
import Navbar from './eg';
import school from "./assests/image/school.jpeg"
import Principal from "./assests/image/principal.jpeg";
import Mission from "./assests/image/Mission.jpg";
import Footer1 from "./egfooter.js"
//import Vision from "./assests/image/Vision.jpeg";
//import Navbar from './Navbar';
function Home() {
    const images=[{src:"./images/scrolling/1.jpeg",alt:"1"},
    {src:"./images/scrolling/c5.jpeg",alt:"2"},
    {src:"./images/scrolling/2.jpeg",alt:"3"},
    {src:"./images/scrolling/c4.jpeg",alt:"4"},
    {src:"./images/scrolling/4.jpeg",alt:"5"},
    {src:"./images/scrolling/10.jpeg",alt:"6"},
    {src:"./images/scrolling/3.jpeg",alt:"7"},
    {src:"./images/scrolling/5.jpeg",alt:"8"},
    {src:"./images/scrolling/7.jpeg",alt:"9"}];
        return (
          <>
            <Navbar/>
          <div className="carousel">
          <Carousel
              nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}
              prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}
          >
              {images.map((image, index) => (
                  <Carousel.Item key={index}>
                      <img
                          className="d-block w-100 carousel-image"
                          src={image.src}
                          alt={image.alt}
                      />
                  </Carousel.Item>
              ))}
          </Carousel>
      </div>
      <div className="announcements-container">
      <h2 className="announcements-title">Our Announcements</h2>
      <div className="buttons-container">
        <Link to="/achivement" className="announcement-button">
          <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/64/FFFFFF/external-achievement-web-and-social-media-flatart-icons-outline-flatarticons.png" alt="Upcoming Events" className="icon" />
          <span>Achivements</span>
        </Link>
        <Link to="/gallery" className="announcement-button">
          <img src="https://img.icons8.com/sf-regular-filled/48/FFFFFF/gallery.png" alt="Latest News" className="icon" />
          <span>Gallery</span>
        </Link>
        <Link to="/act" className="announcement-button">
          <img src="https://img.icons8.com/ios/50/FFFFFF/video-conference.png" alt="Announcements" className="icon" />
          <span>Student Activities</span>
        </Link>
        <Link to="/results" className="announcement-button">
          <img src="https://img.icons8.com/ios/50/FFFFFF/test-results.png" alt="Our School Values" className="icon" />
          <span>Results</span>
        </Link>
      </div>
    </div>
    <div className='parent'>
            <div className="image">
                <img src={school} alt="mlbt" />
            </div>
            <div className="content-section">
                <h5>WELCOME TO</h5>
                <h2>GVP MLBT SCHOOL</h2>
                <p>Gayatri Vidya Parishad MLBT School was established in 1985 by the MLB Trust. The school commenced on June 19, 1986, under the guidance of the eminent teacher VVN Rao. The aim of the school is to support underprivileged children and parents from surrounding areas.

The founders of the school were M. Jaganadha Rao, an eminent advocate and freedom fighter, and Dr. M. Vijaya Lakshmi, who worked with Mahatma Gandhi at Kasturba Ashram. The school was recognized by the Government of Andhra Pradesh in 1993....          
                </p>
                <a href="/about" className="read-more-button">Read More</a>
            </div>
        </div>
        <div className="principal-message-container">
      <div className="message-content">
        <h1>Principal's Message</h1>
<p>On behalf of the community of GVP MLBT School, I welcome all parents of our new students and especially those whose association with the school is just beginning. Choosing the right school for your child is an important decision and I trust the accompanying information will assit you and your child. GVP MLBT School prides itself on getting the best from our students. Parent focus groups and feed back from GVP MBLT School have indicated the important expectations and parents have chosen this school for their child better future.</p>
        <a href="/Principalmssg" className="view-more-button">View More</a>
      </div>
      <div className="principal-image-section">
        <img src={Principal} alt="Principal"/>
        <div className="principal-details">
          <h3>K.Madhura Vani,</h3>
          <h5>Principal</h5>
          <p>M.A.(Eng.,) M.A.(Tel.,) M.Com., M.Ed., M.C.J.,
          PGDPSE.,M.Phil.,(Ph.D.,)</p>
        </div>
      </div>
    </div>
    <div className='vision-mission-container'>
      <div className='vision-mission-content'>
        <div className='image-section'>
          <img src={Mission} alt ="vision & mission"/>
        </div>
        <div className='text-section'>
          <h3>GVP MLBT -VISION & MISSION STATEMENT </h3>
          <h2>"Creating Human Excellence For a Better Society"</h2>
          <div className='mission-statement'>
            <h4>OBJECTIVES OF THE TRUST</h4>
            <p>1.	To promote and uplift Gandhian principles.</p>
            <p>2.	To conduct and promote such charitable activities for the general welfare of the people in the State of Andhra Pradesh.</p>
            <h4>VISION</h4>
            <p>Creating Human Excellence for a Better Society"</p>
            <h4>MISSION</h4>
            <p>Unforld into a world-class organization with strong academic and research base,producing responsible citizens to cater to the changing needs of the society</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Footer1/>
    </div>
      </>
      );
      }
      export default Home;
      