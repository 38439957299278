import React from 'react';
import './results.css';
import Result from './assests/image/results.jpeg'; // Ensure the correct path to the image
import Footer1 from './egfooter';
const Results = () => {
  const studentResults = {
    IX: [
      { id: 1, name: ' P MONIKA ', marks: 541, percentage: 91 },
      { id: 2, name: ' P.APPANNA ', marks: 512, percentage: 89 },
      { id: 3, name: ' G.SARANYA ', marks: 510, percentage: 88 },
    ],
    VIII: [
        { id: 1, name: ' L.ESWARI ', marks: 550, percentage: 92 },
        { id: 2, name: ' B.CHAITANYA ', marks: 540, percentage: 90},
        { id: 3, name: ' N.MANASA ', marks: 517, percentage: 86 },
      ],
      VII: [
        { id: 1, name: ' P.SAMPATH ', marks: 527, percentage: 88 },
        { id: 2, name: ' P.PRUDHVI RAJ ', marks: 518, percentage: 86 },
        { id: 3, name: ' N.SRUTHI ', marks: 516, percentage: 86 },
      ],
      VI: [
        { id: 1, name: ' B.SANJANA ', marks: 568, percentage: 95 },
        { id: 2, name: ' O.MAHIMA ', marks: 566, percentage: 94 },
        { id: 3, name: ' K.TANMAI ', marks: 501, percentage: 84 },
      ],
      V: [
        { id: 1, name: ' N.S.D.HARSHINI ', marks: 398, percentage: 96 },
        { id: 2, name: ' ANEEL ', marks: 396, percentage: 95 },
        { id: 3, name: ' B.VINEETHA ', marks: 395, percentage: 94 },
      ],
      IV: [
        { id: 1, name: ' S.ANUSHA ', marks: 364, percentage: 91 },
        { id: 2, name: ' Y.BARADWAJ ', marks: 361, percentage: 90 },
        { id: 3, name: ' CH.RUTHI ', marks: 354, percentage: 89 },
      ],
      III: [
        { id: 1, name: ' UHA BALA ', marks: 385, percentage: 96 },
        { id: 2, name: ' DAKSHA ', marks: 380, percentage: 95 },
        { id: 3, name: ' JASHWANTH ', marks: 377, percentage: 94 },
      ],
      II: [
        { id: 1, name: ' P.GNANESWARI ‘ 	', marks: 296, percentage: 99 },
        { id: 2, name: ' N.ANVITHA VARSHINI  ', marks: 296, percentage: 99 },
        { id: 3, name: ' S.DURGA TEJASWINI ', marks: 296, percentage: 99 },
      ],
      I: [
        { id: 1, name: ' B.GUNASHEKAR ', marks: 295, percentage: 98 },
        { id: 2, name: ' N.AISHWARYA ', marks: 293, percentage: 96 },
        { id: 3, name: ' B.SARANYA ', marks: 290, percentage: 94 },
      ]

  };

  const renderTable = (className, results) => (
    <div key={className} className="result-table">
      <h2 className='classes'>CLASS {className}</h2>
      <h3 className='classes'>CLASS {className} RESULTS 2023-2024</h3>
      <h3 className='classes'>SCHOOL TOPPERS</h3>
      <table>
        <thead >
          <tr className='theader'>
            <th>S.NO</th>
            <th>NAME OF THE STUDENT</th>
            <th>MARKS</th>
            <th>PERCENTAGE</th>
          </tr>
        </thead>
        <tbody>
          {results.map((student) => (
            <tr key={student.id}>
              <td>{student.id}</td>
              <td>{student.name}</td>
              <td>{student.marks}</td>
              <td>{student.percentage}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <>
    <div className="results-container">
      <div className="result-image">
        <h1></h1>
        <h2 className='classes'>CONGRATULATIONS TO CLASS X STUDENTS</h2>
        <h3 className='classes'>CLASS X RESULTS 2023-2024</h3>
        <img src={Result} alt="Class 10 Results" />
      </div>
      {Object.keys(studentResults)
        .sort((a, b) => b - a)
        .map((className) =>
          renderTable(className, studentResults[className])
        )}
    </div>
    <div><Footer1/></div>
    </>
  );
};

export default Results;
