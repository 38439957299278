import React from "react";
import "./achievement.css";
import Footer1 from "./egfooter.js"

const achievements = [
  {
    imageSrc: "images/achieve/skating.jpeg", // Update the path to your image
    title: "D Priya Anandi sri varshini",
    description: "Winner of Gold Medal in SkateBoarding"

  },
  {
    imageSrc: "images/achieve/boxing.jpeg", // Update the path to your image
    title: "Class-9B M.Alekhya",
    description: "Awarded the Title of State-Level Champion in Boxing for Outstanding Performance"
  }
];

const Achievements = () => {
  return (
    <>
    <div className="achievements-container">
      {achievements.map((achievement, index) => (
        <div className="achievement" key={index}>
          <img src={achievement.imageSrc} alt="Achievement" />
          <h3>{achievement.title}</h3>
          <p>{achievement.description}</p>
        </div>
      ))}
    </div>
    <div><Footer1/></div>
    </>
  );
};

export default Achievements;
