import React from 'react';
import './dresscode.css';
import Footer1 from './egfooter';
const DressCode = () => {
  return (
    <>
    <div className="dress-code">
      <h1 className="title">Dress Code</h1>

      <div className="container1">
        <h2 className="subtitle1">Monday</h2>
        <div className="group">
          <h3 className="heading">PP1 to 5th Class</h3>
          <ul className="list">
            <li>Boys – White Shirt, White Shorts, White Shoes, White Socks & Red or Blue color Belt.</li>
            <li>Girls – White Shirt, White Tunic, White Ribbons, White Shoes & Socks & Red or Blue Belt.</li>
          </ul>
          <h3 className="heading">6th to 10th Class</h3>
          <ul className="list">
            <li>Boys – White Shirt, White full pants, White Shoes, White Socks & Red or Blue color Belt.</li>
            <li>Girls – White Chudidhar with Blue Overcoat, White Ribbons & White Shoes and Socks.</li>
          </ul>
        </div>
      </div>

      <div className="container1">
        <h2 className="subtitle1">Tuesday to Saturday</h2>
        <div className="group">
          <h3 className="heading">PP1 to 5th Class</h3>
          <ul className="list">
            <li>Boys – Blue & White Checks Shirt, Blue Shorts, Black Shoes, Blue Socks & Red or Blue color Belt.</li>
            <li>Girls – Blue & White Checks Shirt, Blue Tunic, Red Ribbons, Black Shoes, Blue Socks & Red or Blue Belt.</li>
          </ul>
          <h3 className="heading">6th to 10th Class</h3>
          <ul className="list">
            <li>Boys – Blue & White Checks Shirt, Blue full pants, Black Shoes, Blue Socks & Red or Blue color Belt.</li>
            <li>Girls – Blue & White Checks Chudidhar Top and Blue Pants with Blue Overcoat, Red Ribbons & Black Shoes and Blue Socks.</li>
          </ul>
        </div>
      </div>
    </div>
    <div><Footer1/></div>
    </>
  );
};

export default DressCode;
